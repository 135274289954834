<template lang="pug" functional>
.matrix-row.circle
  .matrix-item
    .v-donut
      span {{ props.data.achieved }}
  .matrix-item
    .v-donut
      span {{ props.data.required }}
  .matrix-item
    .v-donut
      span {{ props.data.gap }}
</template>

<script>
export default {
  name: 'MatrixGapRightListItem',

  props: {
    data: Object
  }
}
</script>

<style lang="scss" scoped>
.matrix {
  &-row {
    display: flex;
    gap: 16px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &-item {
    &:first-child {
      color: $body-text-2;
    }
    &:nth-last-child(2) {
      color: $color-orange;
    }
    &:last-child {
      color: $color-dark-green;
      .v-donut {
        border-radius: 50%;
        background-color: $secondary-light-green;
      }
    }
    span {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}
</style>
